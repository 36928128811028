// AnimationComponents.js
import styled, { keyframes, css } from "styled-components";
import { useInView } from "react-intersection-observer";
import { mobile } from "../responsive";
import { useLanguage } from "../LanguageContext";

// สร้างแอนิเมชันสำหรับการเฟดและสไลด์
const fadeAndSlideKeyframes = keyframes`
  from {
    opacity: 0;
    transform: translateX(30px);  // เริ่มจากด้านขวา
  }
  to {
    opacity: 1;
    transform: translateX(0px);  // กลับสู่ตำแหน่งปกติ
  }
`;

// ใช้ `css\`\`` เพื่อห่อแอนิเมชัน
const fadeAndSlide = css`
  animation: ${fadeAndSlideKeyframes} 1.2s ease-in-out; // ใช้แอนิเมชันเฟดและสไลด์
`;
const StyledText = styled.p`
  ${({ isInView }) => (isInView ? fadeAndSlide : "none")};
  transition: opacity 0.5s, transform 0.5s;
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: bold;
  text-shadow: 0.5px 0.5px 0.5px lightgray, 0 0 1px lightgray, 0 0 1px lightgray;
  ${mobile({ fontSize: "24px", paddingTop: "50px" })}
`;
const Text = styled.p`
  ${({ isInView }) => (isInView ? fadeAndSlide : "none")};
  transition: opacity 0.5s, transform 0.5s;
  margin-left: 15px;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.4;
  text-shadow: 0.5px 0.5px 0.5px lightgray, 0 0 1px lightgray, 0 0 1px lightgray;
  ${mobile({ fontSize: "18px", paddingRight: "20px" })}
`;

const FadeAndSlideOnScroll = () => {
  const { ref, inView } = useInView({
    triggerOnce: false,
  });
  const { language } = useLanguage();

  return (
    <div ref={ref}>
      <StyledText isInView={inView}>
        {language === "th" ? "AGE CONSULTANTS" : "AGE CONSULTANTS"}
      </StyledText>
      <Text isInView={inView}>
        {language === "th"
          ? "เชี่ยวชาญในการให้บริการจัดหาบุคลากรด้านการตรวจสอบ"
          : "SPECIALIZES IN PROVIDING INSPECTION"}
      </Text>
      <Text isInView={inView}>
        {language === "th"
          ? "และผู้เชี่ยวชาญเฉพาะทาง"
          : "AND SPECIALIST MANPOWER SERVICES."}
      </Text>
    </div>
  );
};

export default FadeAndSlideOnScroll;
