export const sliderItems = {
  projectid: {
    0: {
      bg: "pink",
      projectdetail: {
        projectname1: "Witness UTM UTL, CFP Project",
        projectname2: "at Thaioil, Chonburi",
        logo1: "./images/a0/logoa0-1.png",
        logo2: "",
        logo3: "",
      },
      projectpic: [
        {
          id: 0,
          img: "./images/a0/0.jpg",
        },
        {
          id: 1,
          img: "./images/a0/1.jpg",
        },
        {
          id: 2,
          img: "./images/a0/2.jpg",
        },
        {
          id: 3,
          img: "./images/a0/3.jpg",
        },
        {
          id: 4,
          img: "./images/a0/4.jpg",
        },
        {
          id: 5,
          img: "./images/a0/5.jpg",
        },
        {
          id: 6,
          img: "./images/a0/6.jpg",
        },
        {
          id: 7,
          img: "./images/a0/7.jpg",
        },
        {
          id: 8,
          img: "./images/a0/8.jpg",
        },
        {
          id: 9,
          img: "./images/a0/9.jpg",
        },
      ],
    },
    1: {
      bg: "pink",
      projectdetail: {
        projectname1: "Witness WPQ",
        projectname2: "at SOAGED, Rayong",
        logo1: "./images/a1/logoa1-1.png",
        logo2: "",
        logo3: "",
      },
      projectpic: [
        {
          id: 0,
          img: "./images/a1/0.jpg",
        },
        {
          id: 1,
          img: "./images/a1/1.jpg",
        },
        {
          id: 2,
          img: "./images/a1/2.jpg",
        },
        {
          id: 3,
          img: "./images/a1/3.jpg",
        },
        {
          id: 4,
          img: "./images/a1/4.jpg",
        },
        {
          id: 5,
          img: "./images/a1/5.jpg",
        },
        {
          id: 6,
          img: "./images/a1/6.jpg",
        },
      ],
    },
    2: {
      bg: "pink",
      projectdetail: {
        projectname1: "Witness Mechanical Test, CFP Project",
        projectname2: "at Thaioil, Chonburi",
        logo1: "./images/a2/logoa2-1.png",
        logo2: "",
        logo3: "",
      },
      projectpic: [
        {
          id: 0,
          img: "./images/a2/0.jpg",
        },
        {
          id: 1,
          img: "./images/a2/1.jpg",
        },
        {
          id: 2,
          img: "./images/a2/2.jpg",
        },
        {
          id: 3,
          img: "./images/a2/3.jpg",
        },
        {
          id: 4,
          img: "./images/a2/4.jpg",
        },
        {
          id: 5,
          img: "./images/a2/5.jpg",
        },
        {
          id: 6,
          img: "./images/a2/6.jpg",
        },
        {
          id: 7,
          img: "./images/a2/7.jpg",
        },
        {
          id: 8,
          img: "./images/a2/8.jpg",
        },
      ],
    },
    3: {
      bg: "pink",
      projectdetail: {
        projectname1: "Witness Pre-Assembly",
        projectname2: "at EGAT, Ratchaburi",
        logo1: "./images/a3/logoa3-1.png",
        logo2: "./images/a3/logoa3-2.png",
        logo3: "",
      },
      projectpic: [
        {
          id: 0,
          img: "./images/a3/0.jpg",
        },
        {
          id: 1,
          img: "./images/a3/1.jpg",
        },
        {
          id: 2,
          img: "./images/a3/2.jpg",
        },
        {
          id: 3,
          img: "./images/a3/3.jpg",
        },
        {
          id: 4,
          img: "./images/a3/4.jpg",
        },
        {
          id: 5,
          img: "./images/a3/5.jpg",
        },
        {
          id: 6,
          img: "./images/a3/6.jpg",
        },
        {
          id: 7,
          img: "./images/a3/7.jpg",
        },
        {
          id: 8,
          img: "./images/a3/8.jpg",
        },
        {
          id: 9,
          img: "./images/a3/9.jpg",
        },
        {
          id: 10,
          img: "./images/a3/10.jpg",
        },
        {
          id: 11,
          img: "./images/a3/11.jpg",
        },
      ],
    },
    4: {
      bg: "pink",
      projectdetail: {
        projectname1: "Silo Installation Project",
        projectname2: "at Betagro, Chachoengsao",
        logo1: "./images/a4/logoa4-1.png",
        logo2: "",
        logo3: "",
      },
      projectpic: [
        {
          id: 0,
          img: "./images/a4/0.jpg",
        },
        {
          id: 1,
          img: "./images/a4/1.jpg",
        },
        {
          id: 2,
          img: "./images/a4/2.jpg",
        },
        {
          id: 3,
          img: "./images/a4/3.jpg",
        },
        {
          id: 4,
          img: "./images/a4/4.jpg",
        },
        {
          id: 5,
          img: "./images/a4/5.jpg",
        },
        {
          id: 6,
          img: "./images/a4/6.jpg",
        },
        {
          id: 7,
          img: "./images/a4/7.jpg",
        },
        {
          id: 8,
          img: "./images/a4/8.jpg",
        },
        {
          id: 9,
          img: "./images/a4/9.jpg",
        },
      ],
    },
    5: {
      bg: "pink",
      projectdetail: {
        projectname1: "Witness Pre-Assembly, Lenzing Project",
        projectname2: " at Wood, Prachin Buri",
        logo1: "./images/a5/logoa5-1.png",
        logo2: "./images/a5/logoa5-2.png",
        logo3: "",
      },
      projectpic: [
        {
          id: 0,
          img: "./images/a5/0.jpg",
        },
        {
          id: 1,
          img: "./images/a5/1.jpg",
        },
        {
          id: 2,
          img: "./images/a5/2.jpg",
        },
        {
          id: 3,
          img: "./images/a5/3.jpg",
        },
        {
          id: 4,
          img: "./images/a5/4.jpg",
        },
        {
          id: 5,
          img: "./images/a5/5.jpg",
        },
        {
          id: 6,
          img: "./images/a5/6.jpg",
        },
        {
          id: 7,
          img: "./images/a5/7.jpg",
        },
        {
          id: 8,
          img: "./images/a5/8.jpg",
        },
        {
          id: 9,
          img: "./images/a5/9.jpg",
        },
        {
          id: 10,
          img: "./images/a5/10.jpg",
        },
      ],
    },
  },
};

export const categories = [
  {
    id: 0,
    img: "https://images.ctfassets.net/sfnkq8lmu5d7/1NaIFGyBn0qwXYlNaCJSEl/ad59ce5eefa3c2322b696778185cc749/2021_0825_Kitten_Health.jpg?w=1000&h=750&q=70&fm=webp",
    title: "ddddddd",
  },
  {
    id: 1,
    img: "https://images.ctfassets.net/sfnkq8lmu5d7/1NaIFGyBn0qwXYlNaCJSEl/ad59ce5eefa3c2322b696778185cc749/2021_0825_Kitten_Health.jpg?w=1000&h=750&q=70&fm=webp",
    title: "ddddddd",
  },
  {
    id: 2,
    img: "https://d2zp5xs5cp8zlg.cloudfront.net/image-78806-800.jpg",
    title: "mmmmmmmmmmmmmm",
  },
  {
    id: 3,
    img: "https://images.ctfassets.net/sfnkq8lmu5d7/1NaIFGyBn0qwXYlNaCJSEl/ad59ce5eefa3c2322b696778185cc749/2021_0825_Kitten_Health.jpg?w=1000&h=750&q=70&fm=webp",
  },
  {
    id: 4,
    img: "https://images.ctfassets.net/sfnkq8lmu5d7/1NaIFGyBn0qwXYlNaCJSEl/ad59ce5eefa3c2322b696778185cc749/2021_0825_Kitten_Health.jpg?w=1000&h=750&q=70&fm=webp",
  },
  {
    id: 5,
    img: "https://images.ctfassets.net/sfnkq8lmu5d7/1NaIFGyBn0qwXYlNaCJSEl/ad59ce5eefa3c2322b696778185cc749/2021_0825_Kitten_Health.jpg?w=1000&h=750&q=70&fm=webp",
  },
];

export const popularProducts = [
  {
    id: 1,
    img: "https://www.popcornfor2.com/upload/91/news-full-90462.jpg",
  },
  {
    id: 2,
    img: "https://images.ctfassets.net/sfnkq8lmu5d7/1NaIFGyBn0qwXYlNaCJSEl/ad59ce5eefa3c2322b696778185cc749/2021_0825_Kitten_Health.jpg?w=1000&h=750&q=70&fm=webp",
  },
  {
    id: 3,
    img: "https://d2zp5xs5cp8zlg.cloudfront.net/image-78806-800.jpg",
  },
  {
    id: 4,
    img: "https://www.popcornfor2.com/upload/91/news-full-90462.jpg",
  },
  {
    id: 5,
    img: "https://images.ctfassets.net/sfnkq8lmu5d7/1NaIFGyBn0qwXYlNaCJSEl/ad59ce5eefa3c2322b696778185cc749/2021_0825_Kitten_Health.jpg?w=1000&h=750&q=70&fm=webp",
  },
  {
    id: 6,
    img: "https://d2zp5xs5cp8zlg.cloudfront.net/image-78806-800.jpg",
  },
];
