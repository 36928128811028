import React from "react";
import styled, { keyframes, css } from "styled-components";
import { useInView } from "react-intersection-observer"; // สำหรับการตรวจจับองค์ประกอบที่อยู่ในพื้นที่แสดงผล
import { sliderItems } from "../data";
/* import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import { useRef, useEffect } from "react";
import { register } from "swiper/element/bundle"; */
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
//import "./styles.css";
// import required modules
import { Navigation } from "swiper/modules";
import { mobile } from "../responsive";
import { useLanguage } from "../LanguageContext";

const fadeAndSlideKeyframes = keyframes`
  from {
    opacity: 0;
    transform: translateY(15px);  // เริ่มต้นจากด้านขวา
  }
  to {
    opacity: 1;
    transform: translateY(0px);  // กลับสู่ตำแหน่งปกติ
  }
`;

const fadeAndSlide = css`
  animation: ${fadeAndSlideKeyframes} 0.9s ease-in-out; // ใช้แอนิเมชันเฟดและสไลด์
`;
const Container = styled.div`
  width: 100vw;
  display: flex;
  padding-top: 90px;
  padding-bottom: 60px;
  justify-content: center;
  align-items: center;
  background-color: rgba(14, 70, 163, 1);
  ${mobile({ hight: "20px", paddingTop: "60px" })}
`;

const Wrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(14, 70, 163, 1);
  ${mobile({ width: "95%", padding: "10px 0px" })}
  ${({ isInView }) => (isInView ? fadeAndSlide : "none")};
  transition: opacity 0.5s, transform 0.5s;
`;
const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ isInView }) => (isInView ? fadeAndSlide : "none")};
  transition: opacity 0.5s, transform 0.5s;
`;
const HeadingLine = styled.span`
  color: white;
  font-size: 22px;
  font-weight: bold;
  align-items: center;
  margin-bottom: 30px;
  padding-left: 0px;
  ${mobile({ fontSize: "18px" })}
`;

const ProjectBox = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: whitesmoke;
  margin-bottom: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  &:hover {
    color: darkblue;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  }
  ${mobile({ width: "100%" })}
`;
const ServiceHead = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  border-radius: 16px;
  ${mobile({ height: "70px", fontSize: "12px", marginLeft: "0px" })}
`;

const ProjectNameBox = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 16px;
  border-bottom-left-radius: 0px;
  background-color: rgba(233, 232, 232, 1);
`;

const ProjectName = styled.p`
  font-size: 18px;
  font-weight: bold;
  //width: 100%;
  padding-left: 30px;
  text-align: start;
  align-content: center;
  ${mobile({
    fontSize: "14px",
    textIndent: "10px",
    marginLeft: "10px",
    paddingLeft: "10px",
  })}
`;

const ProjectLogoBox = styled.div`
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: whitesmoke;
`;
const ProjectLogo = styled.img`
  //width: auto;
  height: 50px;
  padding: 0px;
  margin-right: 5px;
  margin-left: 5px;
  ${mobile({ height: "32px", margin: "4px" })}
`;

const SliderBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: whitesmoke;
`;

/* 
const SliderBoxContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  position: relative;
  overflow: hidden;
`;

const Arrow = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 25%;
  left: ${(props) => props.direction === "left" && "0px"};
  right: ${(props) => props.direction === "right" && "0px"};
  background-color: #e7eeee;
  cursor: pointer;
  opacity: 0.5;
  z-index: 2;
`;

const SliderWrapper = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  transition: all 1.5s ease;
  transform: translateX(${(props) => props.slideIndex * -200}px);
`;

const Slide = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
`; */

const ImgContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  width: auto;
  height: 150px;
  flex: 1;
`;

const Projectref = () => {
  /* const [slideIndex, setSlideIndex] = useState(
    Array(Object.values(sliderItems.projectid).length).fill(0)
  ); */

  // const [slideIndex1, setSlideIndex1] = useState(0);
  // const [slideIndex2, setSlideIndex2] = useState(0);

  /* const handleClick = (direction, index, maxLength) => {
    const newSlideIndex = slideIndex.map((projectSlideIndex, i) => {
      if (i === index) {
        if (direction === "left") {
          return projectSlideIndex > 0 ? projectSlideIndex - 1 : maxLength - 1;
        } else {
          return projectSlideIndex < maxLength - 1 ? projectSlideIndex + 1 : 0;
        }
      } else {
        return projectSlideIndex;
      }
    });
    setSlideIndex(newSlideIndex);
  }; */

  const { ref, inView } = useInView({
    triggerOnce: false, // แอนิเมชั่นทำงานทุกครั้งที่องค์ประกอบอยู่ในพื้นที่แสดงผล
  });

  const { language } = useLanguage();

  /* const handleClick2 = (direction) => {
    if (direction === "left") {
      setSlideIndex2((prev) => (prev > 0 ? prev - 1 : 2));
    } else {
      setSlideIndex2((prev) => (prev < 2 ? prev + 1 : 0));
    }
  }; */

  return (
    <div ref={ref}>
      <Container id="projectref">
        <Wrapper isInView={inView}>
          <Heading isInView={inView}>
            <HeadingLine>{language === "th"
                    ? "PROJECT REFERENCE"
                    : "PROJECT REFERENCE"}</HeadingLine>
          </Heading>

          {Object.values(sliderItems.projectid).map((item, index) => (
            <ProjectBox key={index}>
              <ServiceHead>
                <ProjectNameBox>
                  <ProjectName>{item.projectdetail.projectname1}</ProjectName>
                  <ProjectName>{item.projectdetail.projectname2}</ProjectName>
                </ProjectNameBox>
                <ProjectLogoBox>
                  <ProjectLogo src={item.projectdetail.logo1} />
                  <ProjectLogo src={item.projectdetail.logo2} />
                  <ProjectLogo src={item.projectdetail.logo3} />
                </ProjectLogoBox>
              </ServiceHead>

              {/* 
            <SliderBox>
              <SliderBoxContainer>
                <Arrow direction="left" onClick={() => handleClick("left", index, item.projectpic.length)}>
                  <MdArrowBackIosNew size="18px" />
                </Arrow>
                <SliderWrapper slideIndex={slideIndex[index]}>
                  <Slide>
                  {Object.values(item.projectpic).map((pic) => (
                    <ImgContainer key={pic.id}>
                      <Image src={pic.img} />
                    </ImgContainer>
                  ))}
                  </Slide>
                </SliderWrapper>
                <Arrow direction="right" onClick={() => handleClick("right", index, item.projectpic.length)}>
                  <MdArrowForwardIos size="18px" />
                </Arrow>
              </SliderBoxContainer>
            </SliderBox>
            */}

              <SliderBox>
                <Swiper
                  spaceBetween={4}
                  navigation={true}
                  modules={[Navigation]}
                  slidesPerView={3.5}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  {Object.values(item.projectpic).map((pic) => (
                    <SwiperSlide key={pic.id}>
                      <ImgContainer>
                        <Image src={pic.img} />
                      </ImgContainer>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </SliderBox>
            </ProjectBox>
          ))}
        </Wrapper>
      </Container>
    </div>
  );
};

export default Projectref;
