import styled from "styled-components";
import React, { useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
//import FadeInComponent from "./FadeInComponent.js";
import FadeAndSlideOnScroll from "./FadeAndSlideOnScroll.js";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./styles.css";
import "./script.js";
import { FaBars } from "react-icons/fa";
// import required modules
import { EffectFade, Autoplay, Pagination, Navigation } from "swiper/modules";
import { mobile } from "../responsive";
import { useLanguage } from "../LanguageContext";

<script src="script.js"></script>;

const Container = styled.div`
  position: relative;
  width: 100vw;
  max-height: 500px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: wheat;
  ${mobile({ height: "350px" })}
`;
const Div = styled.div`
  //flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  //height: 100%;
  background-color: lavender;
  ${mobile({ hight: "50px" })}
`;

const Img = styled.img`
  width: auto;
  height: 500px;
  object-fit: cover;
  ${mobile({ height: "350px" })}
`;
const NavbarContainer = styled.div`
  z-index: 2;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  width: 100vw;
`;
/* const NavbarBox = styled.div`
  position: absolute;
  z-index: 3;
  height: 65px;
  width: 100vw;
  position: fixed;
  top: 0px;
  display: flex;
  align-items: center;
  background-color: rgba(180, 180, 184, 0.5);
  ${mobile({ height: "70px", paddingTop: "20px" })}
`; */

const NavbarBox = styled.div`
  position: fixed;
  top: ${(props) => (props.hidden ? "-110px" : "0px")}; // ปรับตำแหน่งตามสถานะ
  transition: top 0.3s ease; // เพื่อให้เอฟเฟกต์ซ่อน/แสดง
  z-index: 3;
  height: 75px;
  width: 100vw;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background-color: rgba(60, 60, 60, 0.6);
  ${mobile({ height: "65px", backgroundColor: "rgba(60, 60, 60, 0.6)" })}
`;

const Wrapper = styled.div`
  width: 90vw;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mobile({ justifyContent: "space-between", paddingBottom: "0px" })}
`;

const Left = styled.div`
  flex: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  //padding-top:10px;
  //background-color: #b2c7b2;
  ${mobile({ flex: 9 })}
`;
const Logo = styled.img`
  width: 50px;
  height: auto;
  object-fit: cover;
  ${mobile({ height: "50px", paddingBottom: "8px", paddingTop: "8px" })}
`;
const CompanyName = styled.span`
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  //cursor: pointer;
  padding-left: 20px;
`;

const Right = styled.div`
  flex: 3.5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${mobile({ display: "none" })}
`;
const MenuItems = styled.div`
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  margin-left: 20px;
  text-decoration: none;
  //background-color: #66d0c4;
  &:hover {
    color: lightblue;
  }
`;

const Link = styled.a`
  color: white; /* สีลิงค์ */
  text-decoration: none; /* ลบขีดเส้นใต้ */
  font-weight: bold;
  /* เพิ่มเอฟเฟกต์เมื่อเมาส์ hover */
  &:hover {
    text-decoration: none; /* เพิ่มขีดเส้นใต้เมื่อ hover */
    color: lightblue;
  }
  ${mobile({ marginTop: "40px", fontSize: "18px" })}
`;

const TextContainer = styled.div`
  display: flex;
  width: 50vw;
  height: 100%;
  top: 0px;
  //background-color: #66d0c4;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 100px;
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  ${mobile({ width: "80%",paddingLeft: "85px" })}
`;
/* const TextBox = styled.p`
  //width: 50vw;
  position: absolute;
  z-index: 2;
  //top: 200px;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.5;
  padding-top: 70px;
`;
 */
//////////
const Burger = styled.div`
  display: none;
  ${mobile({
    width: "20vw",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 0.5,
  })}
`;

const BurgerMenu = styled.div`
  display: none;
  cursor: pointer;
  justify-content: flex-end;
  ${mobile({ display: "block" })}// แสดง burger menu เมื่อหน้าจอเล็กกว่า 500px
`;

const DropdownMenu = styled.div`
  font-size: 16px;
  position: fixed;
  top: 65px; //จะเท่ากับค่าความสูงของโมบายNavbarBox
  right: ${(props) => (props.open ? "0" : "-40vw")};
  width: 40vw; // ความกว้าง 40% ของหน้าจอ
  height: 100vh; // สูงเต็มหน้าจอ
  background-color: rgba(14, 70, 163, 0.85);

  /*   background-color: rgba(180, 180, 184, 0.8); // พื้นหลังโปร่งใส
 */
  transition: right 0.3s ease; // สไลด์จากขวาไปซ้าย
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 9;
`;

/////////////
const LanguageDropdown = styled.select`
  background-color: rgba(180, 180, 184, 0);
  border: none;
  color: white;
  font-size: 14px;
  margin-left: 20px;
  cursor: pointer;
  &:hover {
    color: black;
  }
  ${mobile({ display: "none" })}
`;

const MobileLanguageDropdown = styled.select`
  //height: 50px;
  padding: 2px 2px;
  position: relative;
  display: inline-block;
  background-color: rgba(14, 70, 163, 0.85);
  border: none;
  font-weight: bold;
  color: white;
  font-size: 18px;
  //margin-left: 20px;
  margin-top: 40px;
  cursor: pointer;
  ${mobile({ display: "block" })}
`;
///////////////////////////

const Landing = () => {
  const [navbarHidden, setNavbarHidden] = useState(false); // สถานะซ่อน/แสดง Navbar
  const [lastScrollTop, setLastScrollTop] = useState(0);
  ////////////////////
  const [menuOpen, setMenuOpen] = useState(false);
  const { language, setLanguage } = useLanguage();
  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const menuItems = [
    { key: "home", th: "หน้าแรก", en: "HOME" },
    { key: "aboutus", th: "เกี่ยวกับเรา", en: "ABOUT US" },
    { key: "ourservices", th: "บริการของเรา", en: "OUR SERVICES" },
    { key: "projectref", th: "PROJECT REF.", en: "PROJECT REF." },
    { key: "contactus", th: "ติดต่อเรา", en: "CONTACT US" },
  ];

  ////////////////////////

  const handleScroll = () => {
    if (menuOpen) {
      // ถ้าเมนูเปิด
      setMenuOpen(false); // ซ่อนเมนูเมื่อเลื่อน
    }

    const currentScrollTop = window.scrollY; // ตำแหน่งการเลื่อนปัจจุบัน
    if (currentScrollTop > lastScrollTop) {
      // ถ้าเลื่อนลง
      setNavbarHidden(true); // ซ่อน navbar
    } else {
      setNavbarHidden(false); // แสดง navbar
    }
    setLastScrollTop(currentScrollTop); // อัปเดตตำแหน่งการเลื่อนก่อนหน้า
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll); // ตรวจจับการเลื่อน
    return () => {
      window.removeEventListener("scroll", handleScroll); // ลบ listener เมื่อ component ถูกลบ
    };
  }, [lastScrollTop]);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev); // สลับสถานะของเมนู
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <Container id="home">
      <Div>
        <Swiper
          //slidesPerView={'auto'}
          centeredSlidesBounds={true}
          //watchSlidesProgress={true}
          spaceBetween={0}
          centeredSlides={true}
          effect={"fade"}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[EffectFade, Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide centeredSlides={true} centeredSlidesBounds={true}>
            <Img src="./images/landing/landingpic1.jpg" />
          </SwiperSlide>
          <SwiperSlide>
            <Img src="./images/landing/landingpic2.jpg" />
          </SwiperSlide>
          <SwiperSlide>
            <Img src="./images/landing/landingpic3.jpg" />
          </SwiperSlide>
          <SwiperSlide>
            <Img src="./images/landing/landingpic4.jpg" />
          </SwiperSlide>

          <NavbarContainer>
            <NavbarBox hidden={navbarHidden}>
              <Wrapper>
                <Left>
                  <Logo src=".\images\AGE_logo_white.png" />
                  <CompanyName>
                    <Link href="#home">AGE Consultants Co., Ltd.</Link>
                  </CompanyName>
                </Left>

                <Right>
                  {menuItems.map((item) => (
                    <MenuItems key={item.key}>
                      <Link href={`#${item.key}`}>
                        {language === "th" ? item.th : item.en}
                      </Link>
                    </MenuItems>
                  ))}
                  <LanguageDropdown
                    onChange={handleLanguageChange}
                    value={language}
                  >
                    <option value="en">ENG</option>
                    <option value="th">TH</option>
                  </LanguageDropdown>
                </Right>

                <Burger>
                  <BurgerMenu onClick={toggleMenu}>
                    <FaBars size={30} />
                  </BurgerMenu>

                  <DropdownMenu open={menuOpen}>
                    {menuItems.map((item) => (
                      <Link
                        key={item.key}
                        href={`#${item.key}`}
                        onClick={closeMenu}
                      >
                        {language === "th" ? item.th : item.en}
                      </Link>
                    ))}
                    <MobileLanguageDropdown
                      onChange={handleLanguageChange}
                      value={language}
                    >
                      <option value="en">ENG</option>
                      <option value="th">TH</option>
                    </MobileLanguageDropdown>
                  </DropdownMenu>
                </Burger>
              </Wrapper>
            </NavbarBox>
          </NavbarContainer>

          <TextContainer>
            {" "}
            <div>
              <div style={{ height: "600px" }}></div>
              <FadeAndSlideOnScroll />
              <div style={{ height: "600px" }}></div>
            </div>
          </TextContainer>
        </Swiper>
      </Div>
    </Container>
  );
};
export default Landing;
