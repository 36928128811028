import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import styled, { keyframes, css } from "styled-components";
import { useInView } from "react-intersection-observer"; // สำหรับการตรวจจับองค์ประกอบที่อยู่ในพื้นที่แสดงผล
import ReCAPTCHA from "react-google-recaptcha";
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { FaMailBulk } from "react-icons/fa";
import { MdWebAsset } from "react-icons/md";
import { MdLocationPin } from "react-icons/md";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaLine } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
//import { Link } from "react-router-dom";
import { mobile } from "../responsive";
import { useLanguage } from "../LanguageContext";

const fadeAndSlideKeyframes = keyframes`
  from {
    opacity: 0;
    transform: translateY(15px);  // เริ่มต้นจากด้านขวา
  }
  to {
    opacity: 1;
    transform: translateY(0px);  // กลับสู่ตำแหน่งปกติ
  }
`;

const fadeAndSlide = css`
  animation: ${fadeAndSlideKeyframes} 0.9s ease-in-out; // ใช้แอนิเมชันเฟดและสไลด์
`;

const Container = styled.div`
  //height: 400px;
  width: 100vw;
  padding-top: 90px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  ${mobile({ hight: "20px", paddingTop: "60px" })}
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${mobile({ padding: "10px 0px" })}
`;

const HeadingLine = styled.span`
  font-size: 22px;
  font-weight: bold;
  align-items: center;
  margin-bottom: 30px;
  padding-left: 0px;
  ${mobile({ fontSize: "18px" })}
`;
const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  //background-color: #b2c7b2;
  ${({ isInView }) => (isInView ? fadeAndSlide : "none")};
  transition: opacity 0.5s, transform 0.5s;
`;

const Box = styled.div`
  display: flex;
  width: 80%;
  ${mobile({ width: "80%", flexDirection: "column" })}
`;

const SendEmailBox = styled.div`
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  //height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: whitesmoke;
  ${mobile({ fontSize: "12px", marginBottom: "30px" })}
  ${({ isInView }) => (isInView ? fadeAndSlide : "none")};
  transition: opacity 0.5s, transform 0.5s;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  //flex-wrap: wrap;
`;
const Input = styled.input`
  //height: 50px;
  //size:"10px";
  flex: 1;
  //min-width: 30%;
  min-width: 80%;
  margin: 10px 0px 0px 0px;
  padding: 5px 0px 5px 10px;
  border: 2px solid white;
  border-bottom: 2px solid lightgray;
  border-radius: 4px;
  &:hover {
    border: 2px solid lightskyblue;
  }
  ${mobile({ fontSize: "14px" })}
`;
const IconBox = styled.div`
  width: 30px;
`;

const Div = styled.div`
  margin: 10px;
  display: flex;
  justify-content: center;
  ${mobile({ height: "auto", width: "50%" })}
`;
const TextArea = styled.textarea`
  flex: 1;
  min-width: 80%;
  min-height: 80px;
  margin: 10px 0px 0px 0px;
  padding: 5px 0px 5px 10px;
  border: 2px solid white;
  border-bottom: 2px solid lightgray;
  border-radius: 4px;
  resize: none;
  &:hover {
    border: 2px solid lightskyblue;
  }
  ${mobile({ fontSize: "14px" })}
`;

const Button = styled.button`
  width: 100%;
  border: none;
  padding: 15px 40px;
  background-color: blue;
  color: white;
  cursor: pointer;
  margin-top: 15px;
  border-radius: 8px;
  font-weight: bold;
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  ${mobile({ fontSize: "16px" })}//align-content: center;
  //justify-content: center;
`;

const Message = styled.div`
  color: green;
  font-size: 16px;
  margin-top: 5px;
  ${mobile({ fontSize: "16px" })}
`;

const ContactList = styled.p`
  flex: 1;
  width: 70%;
  //font-size: 16px;
  //font-weight: bold;
  text-align: start;
  align-content: flex-start;
  //padding-right: 10px;
  margin-right: 15px;
  line-height: 1.2;
  background-color: whitesmoke;
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  ${mobile({ width: "100%", marginTop: "20px", marginBottom: "40px" })}
  ${({ isInView }) => (isInView ? fadeAndSlide : "none")};
  transition: opacity 0.5s, transform 0.5s;
`;

const PictureBox = styled.img`
  //font-size: 16px;
  //font-weight: bold;
  width: 100%;
  height: cover;
  margin-bottom: 30px;
  text-align: center;
  align-content: center;
  //background-color: blue;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  ${mobile({ height: "200px" })}
`;

const List = styled.div`
  margin-bottom: 30px;
  padding-right: 15px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`;
const NameListItem = styled.div`
  width: 90%;
  margin-left: 0px;
  margin-bottom: 20px;
  text-indent: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  &:hover {
    //color: darkblue;
  }
`;
const ListItem = styled.div`
  width: 90%;
  margin-left: 30px;
  margin-bottom: 10px;
  text-indent: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  &:hover {
    //color: darkblue;
  }
`;

const NameText = styled.p`
  //width: 100%;
  //font-weight: bold;
  margin-left: 15px;
  font-size: 20px;
  font-weight: bold;
  padding-top: 0px;
  padding-left: 10px;
  text-align: start;
  line-height: 1.4;
  text-indent: 0px;
  text-decoration: none;
  &:hover {
    text-decoration: none; /* เพิ่มขีดเส้นใต้เมื่อ hover */
    color: darkblue;
  }
  ${mobile({ fontSize: "16px" })}
`;

const Text = styled.p`
  //width: 100%;
  //font-weight: bold;
  margin-left: 15px;
  font-size: 16px;
  padding-top: 0px;
  padding-left: 10px;
  text-align: start;
  line-height: 1.4;
  text-indent: 0px;
  text-decoration: none;
  &:hover {
    text-decoration: none; /* เพิ่มขีดเส้นใต้เมื่อ hover */
    color: darkblue;
  }
  ${mobile({ fontSize: "14px" })}
`;

const SocialLink = styled.a`
  //color: darkblue; /* สีลิงค์ */
  font-size: 14px;
  text-decoration: none; /* ลบขีดเส้นใต้ */
  /* เพิ่มเอฟเฟกต์เมื่อเมาส์ hover */
  &:hover {
    text-decoration: underline; /* เพิ่มขีดเส้นใต้เมื่อ hover */
  }
`;

const MailLink = styled.a`
  color: black; /* สีลิงค์ */
  font-size: 16px;
  text-decoration: none; /* ลบขีดเส้นใต้ */
  //font-weight: bold;
  /* เพิ่มเอฟเฟกต์เมื่อเมาส์ hover */
  &:hover {
    //color: darkblue;
    text-decoration: underline;
  }
  ${mobile({ fontSize: "14px" })}
`;

const QRCodeBox = styled.img`
  //font-size: 16px;
  //font-weight: bold;
  width: 125px;
  height: 125px;
  margin-top: 0px;
  margin-left: 45px;
  text-align: center;
  align-content: center;
  //background-color: blue;
  ${mobile({ width: "100px", height: "100px" })}
`;

const Contactus = () => {
  const { language } = useLanguage();
  const [capVal, setCapVal] = useState(null);
  const form = useRef();
  const sendEmail = async (e) => {
    e.preventDefault();
    emailjs
      .sendForm("service_rwyji9x", "template_l7ocran", form.current, {
        publicKey: "lX5Ai_bOADfBGr0k1",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setMessageSent(true);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
    e.target.reset();
  };

  const { ref, inView } = useInView({
    triggerOnce: false, // แอนิเมชั่นทำงานทุกครั้งที่องค์ประกอบอยู่ในพื้นที่แสดงผล
  });

  const [messageSent, setMessageSent] = useState(false);

  return (
    <div ref={ref}>
      <Container id="contactus">
        <Wrapper>
          <Heading isInView={inView}>
            <HeadingLine>
              {language === "th" ? "ติดต่อเรา" : "CONTACT US"}
            </HeadingLine>
          </Heading>
          <Box>
            <ContactList isInView={inView}>
              <PictureBox src="./images/contactus/1.jpg" />
              <List>
                <NameListItem>
                  <NameText>AGE Consultants Co., Ltd.</NameText>
                </NameListItem>
                <ListItem>
                  <IconBox>
                    <FaPhoneAlt size="16px" color="#465C50" />
                  </IconBox>
                  <Text>(+66) 86-558-0747</Text>
                </ListItem>
                <ListItem>
                  <IconBox>
                    <IoIosMail size="20px" color="rgb(15,116,205)" />
                  </IconBox>
                  <Text>contact@age-consultants.com</Text>
                  {/* <MailLink href="mailto:contact@age-consultants.com">
                    contact@age-consultants.com
                  </MailLink> */}
                </ListItem>
                <ListItem>
                  <IconBox>
                    <CgWebsite size="18px" color="#465C50" />
                  </IconBox>
                  <Text>www.age-consultants.com</Text>
                </ListItem>
                <ListItem>
                  <IconBox>
                    <MdLocationPin size="22px" color="red" />
                  </IconBox>
                  <Text>
                    {language === "th"
                      ? "121 หมู่ 1 ต.บางไผ่ อ.เมือง จ.นนทบุรี 11000"
                      : "121, Moo 1, Bang Phai, Mueang, Nonthaburi, Thailand, 11000"}
                  </Text>
                </ListItem>
                <ListItem>
                  <IconBox>
                    <FaFacebook size="18px" color="darkblue" />
                  </IconBox>
                  <SocialLink href="https://www.facebook.com/profile.php?id=61560593368056&mibextid=ZbWKwL">
                    <Text>Facebook Page</Text>
                  </SocialLink>
                </ListItem>
                <ListItem>
                  <IconBox>
                    <FaLinkedin size="18px" color="rgb(10,102,194)" />
                  </IconBox>
                  <SocialLink href="https://www.linkedin.com/company/age-consultants-co-ltd/">
                    <Text>Linkedin Page</Text>
                  </SocialLink>
                </ListItem>
                <ListItem>
                  <IconBox>
                    <FaLine size="18px" color="rgb(0,189,0)" />
                  </IconBox>
                  <SocialLink href="https://lin.ee/wK99li4">
                    <Text>LINE Official</Text>
                  </SocialLink>
                </ListItem>
                {/* <ListItem>
                  <QRCodeBox src="./images/contactus/lineqrcode.png" />
                </ListItem> */}
              </List>
            </ContactList>

            <SendEmailBox isInView={inView}>
            <NameListItem>
                  <NameText>{language === "th" ? "ส่งข้อความถึงเรา" : "Message Us"}</NameText>
                </NameListItem>
              <Form ref={form} onSubmit={sendEmail}>
                <Input placeholder={language === "th" ? "ชื่อ-นามสกุล" : "Name-Lastname"} type="text" name="user_name" />
                <Input placeholder={language === "th" ? "E-mail" : "E-mail"} type="email" name="user_email" />
                <Input placeholder={language === "th" ? "เบอร์โทรศัพท์" : "Phone number"} name="user_phone" />
                <Input placeholder={language === "th" ? "หัวข้อ" : "Subject"} name="subject" />
                <TextArea
                  placeholder={language === "th" ? "ข้อความ" : "Message"}
                  name="message"
                  rows={4}
                  cols={1}
                />
                <Div height="auto" width="70%">
                  <ReCAPTCHA
                    sitekey="6LdkyrgpAAAAAMiWtopk1Kq4TZEwV5tfix4OTwmj"
                    onChange={(val) => setCapVal(val)}
                  />
                </Div>
                <Div>
                  <Button type="submit" value="Send" disabled={!capVal}>
                    {language === "th" ? "ส่งข้อความ" : "SEND"}
                  </Button>
                </Div>
                <Div width="80vw">
                  {messageSent && (
                    <Message>Your message has been successfully sent.</Message> // แสดงข้อความเมื่อข้อความถูกส่ง
                  )}
                </Div>
              </Form>
            </SendEmailBox>
          </Box>
        </Wrapper>
      </Container>
    </div>
  );
};

export default Contactus;
