import React from "react";
import styled, { keyframes, css } from "styled-components";
import { useInView } from "react-intersection-observer"; // สำหรับการตรวจจับองค์ประกอบที่อยู่ในพื้นที่แสดงผล
import { mobile } from "../responsive";
import { useLanguage } from "../LanguageContext";

const fadeAndSlideKeyframes = keyframes`
  from {
    opacity: 0;
    transform: translateX(15px);  // เริ่มต้นจากด้านขวา
  }
  to {
    opacity: 1;
    transform: translateX(0px);  // กลับสู่ตำแหน่งปกติ
  }
`;

const fadeAndSlide = css`
  animation: ${fadeAndSlideKeyframes} 0.9s ease-in-out; // ใช้แอนิเมชันเฟดและสไลด์
`;

const Container = styled.div`
  //height: 400px;
  width: 100vw;
  display: flex;
  padding-top: 70px;
  padding-bottom: 40px;
  justify-content: center;
  align-items: center;
  background-color: rgb(221, 221, 221);
  ${mobile({ hight: "20px", paddingTop: "60px" })}
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${mobile({ padding: "10px 0px" })}
  ${({ isInView }) => (isInView ? fadeAndSlide : "none")};
  transition: opacity 0.5s, transform 0.5s;
`;

const HeadingLine = styled.span`
  font-size: 22px;
  font-weight: bold;
  align-items: center;
  margin-bottom: 30px;
  padding-left: 0px;
  ${mobile({ fontSize: "18px" })}
`;
const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  //background-color: #b2c7b2;
`;
const AllService = styled.div`
  //flex: 1;
  //height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgb(221, 221, 221);
`;
const Box = styled.div`
  display: flex;
  width: 70%;
  //height: 400px;
  //margin: 10px;
  //argin-top: 20px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  flex-direction: column;
  align-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  background-color: rgba(238, 238, 238, 1);
  ${mobile({ fontSize: "12px", width: "90%" })}
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
`;
const ServiceHead = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  align-content: center;
  border-top-right-radius: 16px;
  background-color: #c7c8cc;
  ${mobile({ fontSize: "16px", height: "60px" })}
`;
const ServiceBox = styled.div`
  flex: 1.5;
  font-size: 18px;
  font-weight: bold;
  //width: 200px;
  height: 60px;
  text-align: center;
  align-content: center;
  color: aliceblue;
  border-top-left-radius: 16px;
  background-color: blue;
  ${mobile({ fontSize: "16px", height: "60px" })}
`;

const ServiceText = styled.p`
  flex: 3;
  //width: 60%;
  height: 60px;
  font-size: 18px;
  font-weight: bold;
  text-align: start;
  align-content: center;
  padding-left: 20px;
  ${mobile({ fontSize: "16px", height: "60px", paddingRight: "28px" })}
`;
const ServiceDesc = styled.p`
  width: 90%;
  font-size: 16px;
  //padding: 30px;
  text-align: start;
  line-height: 1.4;
  text-indent: 30px;
`;
const Desc = styled.p`
  width: 100%;
  //font-size: 16px;
  padding-top: 20px;
  padding-left: 30px;
  //text-align: start;
  //line-height: 1.4;
  //text-indent: 30px;
  ${mobile({ fontSize: "14px" })}
`;

const List = styled.p`
  list-style-type: circle;
  list-style-position: inside;
  margin-top: 8px;
  ${mobile({ fontSize: "14px" })}
`;

const Ourservice = () => {
  const { ref, inView } = useInView({
    triggerOnce: false, // แอนิเมชั่นทำงานทุกครั้งที่องค์ประกอบอยู่ในพื้นที่แสดงผล
  });

  const { language } = useLanguage();

  return (
    <div ref={ref}>
      <Container id="ourservices">
        <Wrapper isInView={inView}>
          <Heading>
            <HeadingLine>
              {language === "th" ? "บริการของเรา" : "OUR SERVICES"}
            </HeadingLine>
          </Heading>
          <AllService>
            <Box>
              {language === "th" ? (
                <ServiceHead>
                  <ServiceBox>Service 1</ServiceBox>
                  <ServiceText>ให้บริการจัดหาบุคลากร</ServiceText>
                </ServiceHead>
              ) : (
                <ServiceHead>
                  <ServiceBox>Service 1</ServiceBox>
                  <ServiceText>MANPOWER SUPPLY</ServiceText>
                </ServiceHead>
              )}
              {language === "th" ? (
                <ServiceDesc>
                  <Desc>
                    เราให้บริการจัดหาบุคลากรผู้เชี่ยวชาญ
                    ซึ่งมีประสบการณ์และได้รับการรับรองตามมาตรฐานสากลในการให้บริการตรวจสอบทางวิศวกรรม
                    ดังต่อไปนี้
                  </Desc>
                  <List>
                    <ul>
                      <li>CSWIP 3.0/ 3.1/ 3.2 Inspector</li>
                      <li>Certified Welding Inspector (CWI)</li>
                      <li>BGAS-CSWIP Inspector</li>
                      <li>NACE/ AMPP/ SSPC Inspector</li>
                      <li>API Inspector</li>
                      <li>E&I Inspector</li>
                      <li>Auditor (ISO9001, ISO45001, and ISO14001)</li>
                      <li>NDT Operator (PT, MT, UT, PAUT, RI and TOFD)</li>
                      <li>Expediter</li>
                    </ul>
                  </List>
                  <Desc>
                    นอกจากนี้
                    เรายังให้บริการจัดหาผู้รับเหมาและช่างฝีมือระดับมืออาชีพ
                    ดังต่อไปนี้
                  </Desc>
                  <List>
                    <ul>
                      <li>Supervisor</li>
                      <li>Foreman</li>
                      <li>Welder</li>
                      <li>Fitter</li>
                    </ul>
                  </List>
                </ServiceDesc>
              ) : (
                <ServiceDesc>
                  <Desc>
                    We provide quality specialists certified under international
                    standards as follows:
                  </Desc>
                  <List>
                    <ul>
                      <li>CSWIP 3.0/ 3.1/ 3.2 Inspector</li>
                      <li>Certified Welding Inspector (CWI)</li>
                      <li>BGAS-CSWIP Inspector</li>
                      <li>NACE/ AMPP/ SSPC Inspector</li>
                      <li>API Inspector</li>
                      <li>E&I Inspector</li>
                      <li>Auditor (ISO9001, ISO45001, and ISO14001)</li>
                      <li>NDT Operator (PT, MT, UT, PAUT, RI and TOFD)</li>
                      <li>Expediter</li>
                    </ul>
                  </List>
                  <Desc>
                    Furthermore, we also provided professional-level contractor
                    and skilled workers as follows:
                  </Desc>
                  <List>
                    <ul>
                      <li>Supervisor</li>
                      <li>Foreman</li>
                      <li>Welder</li>
                      <li>Fitter</li>
                    </ul>
                  </List>
                </ServiceDesc>
              )}
            </Box>
            <Box>
              <ServiceHead>
                <ServiceBox>Service 2</ServiceBox>
                <ServiceText>
                  {language === "th"
                    ? "รับจัดทำเอกสาร PQR/ WPS/ WPQ"
                    : "PQR/ WPS/ WPQ"}
                </ServiceText>
              </ServiceHead>
              {language === "th" ? (
                <ServiceDesc>
                  <Desc>
                    บริการให้คำปรึกษาด้านงานเชื่อมเพื่อจัดทำเอกสาร PQR/ WPS/ WPQ
                    ที่เป็นไปตาม international code และ standards
                    ซึ่งบริการของเรานั้นครอบคลุมทุกความต้องการด้านงานเชื่อมภายใต้ความต้องการของลูกค้า
                  </Desc>
                  <Desc><b>Procedure Qualification Record (PQR)</b></Desc>
                  <List>
                    <ul>
                      <li>
                        ให้บริการทั้งออนไลน์หรือนอกสถานที่เพื่อรับทราบความต้องการของลูกค้า
                      </li>
                      <li>
                        ให้บริการนอกสถานที่เพื่อควบคุมและบันทึกผลงานเชื่อม
                      </li>
                      <li>ให้บริการ mechanical test</li>
                      <li>ให้บริการจัดเตรียมเอกสาร PQR</li>
                    </ul>
                  </List>
                  <Desc><b>Welding Procedure Specification (WPS)</b></Desc>
                  <List>
                    <ul>
                      <li>ให้บริการจัดเตรียม WPS ตาม PQR ที่เกี่ยวข้อง</li>
                      <li>
                        จัดเตรียม WPS ตาม international code และ standards
                        ให้เป็นไปตามข้อกำหนดของลูกค้า
                      </li>
                    </ul>
                  </List>
                  <Desc>
                  <b>Welder or Welding Operator Performance Qualification (WPQ)</b>
                  </Desc>
                  <List>
                    <ul>
                      <li>
                        ให้บริการจัดสอบความสามารถช่างเชื่อมตาม WPS
                        ที่ได้รับการอนุมัติ
                      </li>
                      <li>
                        เข้าร่วมสังเกตการณ์เพื่อยืนยันความสามารถของช่างเชื่อมว่าเป็นไปตาม
                        code และ standards ที่เกี่ยวข้อง
                      </li>
                      <li>
                        จัดทำ welder certificate และ welder card
                        ตามความต้องการของลูกค้า
                      </li>
                    </ul>
                  </List>
                </ServiceDesc>
              ) : (
                <ServiceDesc>
                  <Desc>
                    We provided welding consultant services to create PQR / WPS
                    / WPQ according to related international codes and
                    standards. our services can cover welding requirements of
                    your project.
                  </Desc>
                  <Desc><b>Procedure Qualification Record (PQR)</b></Desc>
                  <List>
                    <ul>
                      <li>
                        Online or onsite services to recognize requirements
                      </li>
                      <li>Onsite welding control and record services</li>
                      <li>Mechanical test services</li>
                      <li>PQR preparing service</li>
                    </ul>
                  </List>
                  <Desc><b>Welding Procedure Specification (WPS)</b></Desc>
                  <List>
                    <ul>
                      <li>WPS preparing services according to related PQR</li>
                      <li>
                        Follow related international CODE and Standard that be
                        in line with customer requirements
                      </li>
                    </ul>
                  </List>
                  <Desc>
                  <b>Welder or Welding Operator Performance Qualification (WPQ)</b>
                  </Desc>
                  <List>
                    <ul>
                      <li>Exam welder performance according to approved WPS</li>
                      <li>
                        Witness to be confirm welder skill as per related CODE
                        and Standard
                      </li>
                      <li>Exam welder performance according to approved WPS</li>
                    </ul>
                  </List>
                </ServiceDesc>
              )}
            </Box>
            <Box>
              <ServiceHead>
                <ServiceBox>Service 3</ServiceBox>
                <ServiceText>
                  {language === "th"
                    ? "ตัวแทนจำหน่ายผลิตภัณฑ์และบริการคุณภาพ"
                    : "DEALER SERVICES"}
                </ServiceText>
              </ServiceHead>
              {language === "th" ? (
                <ServiceDesc>
                  <Desc>
                    เรามุ่งมั่นนำเสนอผลิตภัณฑ์และบริการคุณภาพสูงสำหรับอุตสาหกรรมด้านงานเชื่อมและงานวิศวกรรม
                    ด้วยทีมงานผู้เชี่ยวชาญพร้อมให้คำปรึกษาและแนะนำสินค้าที่เหมาะสมกับความต้องการของลูกค้า
                    ไม่ว่าจะเป็น ลวดเชื่อม เครื่องเชื่อม เครื่องตัด
                    อุปกรณ์งานเชื่อม รวมถึง Mobile Crane, Overhead Crane และ LNG
                    สำหรับอุตสาหกรรม
                    ทุกผลิตภัณฑ์และบริการของเราได้รับการคัดสรรอย่างพิถีพิถัน
                    เพื่อส่งมอบผลงานที่มีประสิทธิภาพและคุณภาพในระดับสูงสุด
                  </Desc>
                  <List>
                    <ul>
                      <li>Welding consumables</li>
                      <li>Welding machines</li>
                      <li>welding accessories</li>
                      <li>cutting machines</li>
                      <li>mobile crane</li>
                      <li>overhead crane</li>
                      <li>LNG</li>
                      <li>อุปกรณ์และเครื่องมืออื่นๆด้านงานวิศวกรรม</li>
                    </ul>
                  </List>
                </ServiceDesc>
              ) : (
                <ServiceDesc>
                  <Desc>
                    We provided high-quality products and services for the
                    welding and engineering industries. With our team of
                    experts, we are ready to provide consultations and recommend
                    products that suit each customer's needs, whether welding
                    consumables, welding machines, cutting machines, welding
                    accessories, as well as Mobile Cranes, Overhead Cranes, and
                    LNG for industries. All of our products and services undergo
                    a meticulous selection process to deliver efficient and
                    highest quality results.
                  </Desc>
                  <List>
                    <ul>
                      <li>Welding consumables</li>
                      <li>Welding machines</li>
                      <li>welding accessories</li>
                      <li>cutting machines</li>
                      <li>mobile crane</li>
                      <li>overhead crane</li>
                      <li>LNG</li>
                      <li>ETC.</li>
                    </ul>
                  </List>
                </ServiceDesc>
              )}
            </Box>
          </AllService>
        </Wrapper>
      </Container>
    </div>
  );
};

export default Ourservice;
