import React, { createContext, useState, useContext } from "react";

// สร้าง context พร้อมค่าเริ่มต้น
const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("th");

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Hook สำหรับใช้ language context
export const useLanguage = () => {
  return useContext(LanguageContext);
};
