import React from "react";
import styled, { keyframes, css } from "styled-components";
import { useInView } from "react-intersection-observer"; // สำหรับการตรวจจับองค์ประกอบที่อยู่ในพื้นที่แสดงผล
import { mobile } from "../responsive";
import { useLanguage } from "../LanguageContext";

// สร้างแอนิเมชั่นสำหรับการ fade และ slide
const fadeAndSlideKeyframes = keyframes`
  from {
    opacity: 0;
    transform: translateY(15px);  // เริ่มต้นจากด้านขวา
  }
  to {
    opacity: 1;
    transform: translateY(0px);  // กลับสู่ตำแหน่งปกติ
  }
`;

const fadeAndSlide = css`
  animation: ${fadeAndSlideKeyframes} 0.9s ease-in-out; // ใช้แอนิเมชันเฟดและสไลด์
`;

const Container = styled.div`
  //height: 350px;
  width: 100vw;
  padding-top: 85px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  //background-color: #e4b5b5;
  ${mobile({ height: "auto", paddingTop: "70px" })}
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${mobile({ flexDirection: "column" })}
`;
const Left = styled.div`
  //flex: 1;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mobile({ width: "90%", marginBottom: "10px" })}//background-color: #b2c7b2;
`;

const HeadingLine = styled.span`
  font-size: 22px;
  font-weight: bold;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 0px;
  ${mobile({ fontSize: "18px" })}
`;
const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  //background-color: #b2c7b2;
  ${({ isInView }) => (isInView ? fadeAndSlide : "none")};
  transition: opacity 0.5s, transform 0.5s;
`;
const Desc = styled.p`
  width: 80%;
  font-size: 18px;
  margin: 8px 0px;
  line-height: 1.5;
  text-indent: 40px;
  ${({ isInView }) => (isInView ? fadeAndSlide : "none")};
  transition: opacity 0.5s, transform 0.5s;
  ${mobile({ fontSize: "14px" })}
`;
const Right = styled.div`
  //flex: 1;
  //width: 30vw;
  display: flex;
  flex-direction: column;
  //margin-top: 5%;
  align-items: center;
  justify-content: center;
  ${mobile({ flex: 2, justifyContent: "center", marginTop: "10px" })}
`;

const ItemBox = styled.div`
  //width: 90%;
  //height: 400px;
  //margin: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  background-color: rgba(238, 238, 238, 1);
  ${mobile({
    width: "200px",
    fontSize: "12px",
    marginTop: "20px",
    marginLeft: "10px",
    borderRadius: "8px",
  })}
  &:hover {
    //padding-top: 2px;
    //padding-bottom: 2px;
    color: darkblue;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  ${({ isInView }) => (isInView ? fadeAndSlide : "none")};
  transition: opacity 0.5s, transform 0.5s;
`;

const IconBox = styled.div`
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  //background-color: rgba(238, 238, 238,1);
  ${mobile({ fontSize: "12px", marginLeft: "10px" })}
`;

const Icon = styled.img`
  //font-size: 16px;
  //font-weight: bold;
  width: 50px;
  height: 50px;
  padding-left: 30px;
  //align-items: center;
  //background-color: blue;
  ${mobile({
    height: "20px",
    width: "20px",
    marginLeft: "3px",
    paddingLeft: "10px",
  })}
`;

const Items = styled.div`
  font-size: 20px;
  font-weight: bold;
  width: 230px;
  height: 70px;
  margin: 10px;
  text-align: center;
  align-content: center;
  padding-right: 10px;
  //background-color: #66d0c4;
  ${mobile({
    width: "200px",
    height: "30px",
    fontSize: "12px",
    marginLeft: "10px",
  })}
`;

// คอมโพเนนต์ที่มีข้อความสองบรรทัดพร้อมความหน่วงเวลาที่ต่างกัน
const AboutUsComponent = () => {
  const { ref, inView } = useInView({
    triggerOnce: false, // แอนิเมชั่นทำงานทุกครั้งที่องค์ประกอบอยู่ในพื้นที่แสดงผล
  });

  const { language } = useLanguage();
  const aboutUsText = {
    th: [
      "ด้วยแนวโน้มการขยายตัวอย่างต่อเนื่องของอุตสาหกรรมการก่อสร้างและการซ่อมบำรุง ทำให้ความต้องการด้านบุคลากรที่มีความรู้และความชำนาญเฉพาะทางด้านวิศวกรรมและการตรวจสอบยังคงเป็นที่ต้องการอยู่เสมอ AGE Consultants จึงได้ก่อตั้งขึ้นจากความมุ่งมั่นที่จะส่งมอบบริการจากบุคลากรที่มีความเชี่ยวชาญในด้านการตรวจสอบทางวิศวกรรม พร้อมทั้งบุคลากรที่เชี่ยวชาญในสาขาต่างๆ ที่ได้รับการรับรองตามมาตรฐานสากล",
      "ด้วยระบบการทำงานที่ทันสมัย และได้ออกแบบมาเพื่อตอบสนองต่อความต้องการของลูกค้า ช่วยให้การประสานงานเป็นไปอย่างไร้ที่ติ ส่งผลให้ผลงานที่ส่งมอบมีคุณภาพ เชื่อถือได้ และได้รับการยอมรับตามมาตรฐานสากลในราคาที่สมเหตุสมผล",
      "นอกจากนี้ เรายังมีบริการให้คำปรึกษาและการจัดทำเอกสารที่เกี่ยวข้องกับการตรวจสอบโดยผู้เชี่ยวชาญอย่างครบวงจร เพื่อตอบสนองทุกความต้องการของลูกค้าด้วยความเป็นมืออาชีพ",
    ],
    en: [
      "Due to continuous growth trend in the construction and maintenance industry, there is a higher demand for specialized engineering and inspection personnel. AGE Consultants was established to fulfill this demand by offering expert personnel in engineering inspection, along with expert certified personnel in various fields who follow international CODE and Standards.",
      "Our working system has been designed to respond client requirements, ensure the coordination and high-quality work that meets international standards at competitive price rates.",
      "In addition, we also provided consulting services and document preparation related to engineering inspections by expert certified personnel, for comprehensive of all client requirements.",
    ],
  };

  const aboutUsItems = {
    th: [
      "ผู้เชี่ยวชาญที่ผ่านการรับรอง",
      "บริการที่ยอดเยี่ยม",
      "ราคาสมเหตุสมผล",
    ],
    en: [
      "Expert certified personnel",
      "Excellent service",
      "Competitive price",
    ],
  };

  return (
    <div ref={ref}>
      <Container id="aboutus">
        <Wrapper>
          <Left>
            <Heading isInView={inView}>
              <HeadingLine>
                {language === "th" ? "เกี่ยวกับเรา" : "ABOUT US"}
              </HeadingLine>
            </Heading>
            {aboutUsText[language].map((text, index) => (
              <Desc key={index} isInView={inView}>
                {text}
              </Desc>
            ))}
          </Left>
          <Right>
            {aboutUsItems[language].map((item, index) => (
              <ItemBox key={index} isInView={inView}>
                <IconBox>
                  <Icon src={`./images/aboutus/${index + 1}.png`} />
                </IconBox>
                <Items>{item}</Items>
              </ItemBox>
            ))}
          </Right>
        </Wrapper>
      </Container>
    </div>
  );
};

export default AboutUsComponent; // ส่งออกคอมโพเนนต์เพื่อใช้ในแอปพลิเคชัน
