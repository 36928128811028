import React from "react";
/* import Navbar from "../components/Navbar";
import Announcement from "../components/Announcement"; */
/* import Slider from "../components/Slider";
import Categories from "../components/Categories";
import Products from "../components/Products";
import Newsletter from "../components/Newsletter"; */
import Footer from "../components/Footer";
import Landing from "../components/Landing";
import Aboutus from "../components/Aboutus";
import Corevalue from "../components/Corevalue";
import Ourservice from "../components/Ourservice";
import Contactus from "../components/Contactus";
import Projectref from "../components/Projectref";
import Copyright from "../components/Copyright";
const Home = () => {
  return (
    <div>
      {/*<Announcement />
       */}
      {/* <Navbar />  */}
      <Landing />
      <Aboutus />
      <Corevalue />
      <Ourservice />
      <Projectref />
      <Contactus />
      <Footer />
      <Copyright />
    </div>
  );
};

export default Home;
