import React from "react";
/* import styled from "styled-components";
import { mobile } from "../responsive";
 */
import AboutUsComponent from "./AboutUsComponent";

const Aboutus = () => {
  return (
    <>
      <div>
        <div style={{ height: "0px" }}></div>
        <AboutUsComponent />
        <div style={{ height: "0px" }}></div>
      </div>
    </>
  );
};

export default Aboutus;
