import React from "react";
import styled, { keyframes, css } from "styled-components";
import { useInView } from "react-intersection-observer";
import { mobile } from "../responsive";
import { useLanguage } from "../LanguageContext";

const fadeKeyframes = keyframes`
  from {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const fadeAndSlide = (delay) => css`
  animation: ${fadeKeyframes} 0.5s ease-in-out ${delay}s; // ใช้แอนิเมชันเฟดและสไลด์
`;

const Container = styled.div`
  //height: 400px;
  width: 100vw;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  //background-color: lightgreen;
  ${mobile({ height: "auto",paddingTop: "20px" })}
`;
const Wrapper = styled.div`
  //background-color: rebeccapurple;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${mobile({ padding: "10px 0px" })}
`;

const HeadingLine = styled.span`
  font-size: 22px;
  font-weight: bold;
  align-items: center;
  margin-bottom: 30px;
  padding-left: 0px;
  ${mobile({ fontSize: "18px" })}
`;
const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  //background-color: #b2c7b2;
  ${({ isInView, delay }) =>
    isInView
      ? fadeAndSlide(delay)
      : css`
          opacity: 0;
          visibility: hidden;
        `};
  /*   ${({ isInView }) => (isInView ? fadeAndSlide : "none")};
 */
  transition: opacity 0.5s;
`;
const ValueBox = styled.div`
  //background-color: black;
  //width: 100vw;
  display: flex;
  max-width: 1000px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${mobile({ width: "90vw", flexDirection: "column" })}
`;
const Box = styled.div`
  display: flex;
  width: 400px;
  height: 160px;
  margin: 20px;
  align-content: center;
  background-color: whitesmoke;
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  ${mobile({
    height: "90px",
    width: "95%",
    fontSize: "12px",
    margin: "10px",
  })}
  &:hover {
    color: darkblue;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  ${({ isInView, delay }) =>
    isInView
      ? fadeAndSlide(delay)
      : css`
          opacity: 0;
          visibility: hidden;
        `};
  /*   ${({ isInView }) => (isInView ? fadeAndSlide : "none")};
 */
  transition: opacity 0.5s;
`;
const PictureBox = styled.img`
  width: 160px;
  height: 100%;
  text-align: center;
  align-content: center;
  background-color: blue;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  ${mobile({ height: "90px", width: "auto", fontSize: "12px" })}
`;

const Desc = styled.p`
  width: 60%;
  font-size: 18px;
  font-weight: bold;
  text-align: start;
  align-content: center;
  padding: 20px;
  line-height: 1.3;
  text-indent: 20px;
  ${mobile({
    fontSize: "14px",
    width: "90%",
    marginLeft: "8px",
    textIndent: "15px",
  })}
`;

const Corevalue = () => {
  const { ref, inView } = useInView({
    triggerOnce: false, // แอนิเมชันทำงานทุกครั้งที่องค์ประกอบอยู่ในพื้นที่แสดงผล
  });

  const { language } = useLanguage();
  const aboutUsItems = {
    th: [
      "พัฒนาขีดจำกัดการให้บริการอย่างต่อเนื่อง",
      "เป้าหมายของลูกค้าคือเป้าหมายของเรา",
      "การติดต่อประสานงานอย่างเป็นระบบ รวมไปถึงการรายงานผลที่ชัดเจน",
      "ยืดหยุ่น และเตรียมพร้อมในการเปลี่ยนแปลงตามเป้าหมาย และความต้องการของลูกค้าอยู่เสมอ",
    ],
    en: [
      "Continuous improvement of our services.",
      "The client’s goal is our goal.",
      "Systematic communication and reliable reporting.",
      "Always be flexible and ready for changes according to the client's goals and requirements.",
    ],
  };
  return (
    <div ref={ref}>
      <Container>
        <Wrapper>
          <Heading isInView={inView} delay={0.7}>
            <HeadingLine>
              {language === "th" ? "ค่านิยม" : "CORE VALUE"}
            </HeadingLine>
          </Heading>

          <ValueBox>
            {aboutUsItems[language].map((item, index) => (
              <Box key={index} isInView={inView} delay={0.5}>
                <PictureBox src={`./images/corevalue/${index + 1}.jpg`} />
                <Desc>{item}</Desc>
              </Box>
            ))}
          </ValueBox>
        </Wrapper>
      </Container>
    </div>
  );
};

export default Corevalue;
