import React from "react";
import styled from "styled-components";
import { mobile } from "../responsive";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
//import { FaLine } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { FaMailBulk } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
import { MdWebAsset } from "react-icons/md";
import { MdLocationPin } from "react-icons/md";
import { FaLine } from "react-icons/fa";
import { useLanguage } from "../LanguageContext";

const Container = styled.div`
  display: flex;
  margin-top: 20px;
  ${mobile({ display: "none" })}
`;
const Left = styled.div`
  flex: 1.5;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-left: 40px;
`;
const LogoBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  //background-color: #b2c7b2;
  margin-bottom: 10px;
`;
const Logo = styled.img`
  width: 45px;
  height: 50px;
  object-fit: cover;
`;
const CompanyName = styled.span`
  font-size: 18px;
  font-weight: bold;
  align-items: center;
  cursor: pointer;
  padding-left: 10px;
`;

const Desc = styled.p`
  font-size: 16px;
  margin: 0px 0px 20px 0px;
  line-height: 1.4;
  text-indent: 20px;
`;

const SocialContainer = styled.div`
  display: flex;
`;

const SocialLink = styled.a`
  //color: darkblue; /* สีลิงค์ */
  font-size: 14px;
  text-decoration: none; /* ลบขีดเส้นใต้ */
  padding-left: 10px;
  /* เพิ่มเอฟเฟกต์เมื่อเมาส์ hover */
  &:hover {
    text-decoration: underline; /* เพิ่มขีดเส้นใต้เมื่อ hover */
    //color: darkblue;
  }
`;
const Center = styled.div`
  flex: 0.5;
  padding: 20px;
`;

const Title = styled.h3`
  font-size: 16px;
  margin-bottom: 15px;
`;

const List = styled.ul`
  font-size: 14px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const ListItem = styled.li`
  width: 100%;
  margin-left: 20px;
  margin-bottom: 10px;
  font-size: 12px;
`;

const Link = styled.a`
  color: black; /* สีลิงค์ */
  text-decoration: none; /* ลบขีดเส้นใต้ */
  //font-weight: bold;
  /* เพิ่มเอฟเฟกต์เมื่อเมาส์ hover */
  &:hover {
    text-decoration: none; /* เพิ่มขีดเส้นใต้เมื่อ hover */
    color: darkblue;
    font-weight: bold;
  }
`;

const Right = styled.div`
  flex: 1.5;
  padding: 20px;
`;

const ContactList = styled.div`
  width: 90%;
  margin-left: 30px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  &:hover {
    color: darkblue;
  }
`;

const IconBox = styled.div`
  width: 10px;
`;
const Text = styled.p`
  //width: 100%;
  margin-left: 0px;
  font-size: 14px;
  padding-top: 0px;
  padding-left: 10px;
  text-align: start;
  line-height: 1.4;
  text-indent: 10px;
`;

const MailLink = styled.a`
  color: black; /* สีลิงค์ */
  font-size: 14px;
  text-decoration: none; /* ลบขีดเส้นใต้ */
  padding-left: 20px;
  /* เพิ่มเอฟเฟกต์เมื่อเมาส์ hover */
  &:hover {
    text-decoration: none; /* เพิ่มขีดเส้นใต้เมื่อ hover */
    color: darkblue;
  }
`;

const Footer = () => {
  const { language } = useLanguage();

  return (
    <Container>
      <Left>
        <LogoBox>
          <Logo src=".\images\AGE_logo_black.png" />
          <CompanyName>
            <Link href="#home">AGE Consultants Co., Ltd.</Link>
          </CompanyName>
        </LogoBox>
        <Desc>
          {language === "th"
            ? "เชี่ยวชาญในการให้บริการจัดหาบุคลากรด้านการตรวจสอบและผู้เชี่ยวชาญเฉพาะทาง"
            : "AGE Consultants, specializes in providing inspection and specialist manpower services."}
        </Desc>

        <SocialContainer>
          <Desc>
            {language === "th"
              ? "ติดตามข่าวสารของเราได้ที่นี่ : "
              : "Follow Our News :"}
          </Desc>
          <SocialLink href="https://www.facebook.com/profile.php?id=61560593368056&mibextid=ZbWKwL">
            <FaFacebook size="25px" color="darkblue" />
          </SocialLink>
          <SocialLink href="https://www.linkedin.com/company/age-consultants-co-ltd/">
            <FaLinkedin size="25px" color="rgb(10,102,194)" />
          </SocialLink>
          <SocialLink href="https://lin.ee/wK99li4">
            <FaLine size="25px" color="rgb(5,200,85)" />
          </SocialLink>
        </SocialContainer>
      </Left>
      <Center>
        <Title>{language === "th" ? "QUICK LINK" : "QUICK LINK"}</Title>
        <List>
          <ListItem>
            <Link href="#home">{language === "th" ? "หน้าแรก" : "HOME"}</Link>
          </ListItem>
          <ListItem>
            <Link href="#aboutus">
              {language === "th" ? "เกี่ยวกับเรา" : "ABOUT US"}
            </Link>
          </ListItem>
          <ListItem>
            <Link href="#ourservices">
              {language === "th" ? "บริการ" : "OUR SERVICES"}
            </Link>
          </ListItem>
          <ListItem>
            <Link href="#projectref">
              {language === "th" ? "PROJECT REF." : "PROJECT REF."}
            </Link>
          </ListItem>
          <ListItem>
            <Link href="#contactus">
              {language === "th" ? "ติดต่อเรา" : "CONTACT US"}
            </Link>
          </ListItem>
        </List>
      </Center>

      <Right>
        <Title>{language === "th" ? "ติดต่อเรา" : "CONTACT US"}</Title>
        <List>
          <ContactList>
            <IconBox>
              <FaPhoneAlt size="16px" color="#465C50" />
            </IconBox>
            <Text>(+66) 86-558-0747</Text>
          </ContactList>
          <ContactList>
            <IconBox>
              <IoIosMail size="22px" color="rgb(15,116,205)" />
            </IconBox>
            <Text>contact@age-consultants.com</Text>
          </ContactList>
          {/* <ContactList>
            <IconBox>
              <FaLine size="16px" />
            </IconBox>
            <Text>@315nzizs</Text>
          </ContactList> */}
          <ContactList>
            <IconBox>
              <CgWebsite size="18px" color="#465C50" />
            </IconBox>
            <Text>www.age-consultants.com</Text>
          </ContactList>
          <ContactList>
            <IconBox>
              <MdLocationPin size="22px" color="red" />
            </IconBox>
            <Text>
              {language === "th"
                ? "121 หมู่ 1 ต.บางไผ่ อ.เมือง จ.นนทบุรี 11000"
                : "121, Moo 1, Bang Phai, Mueang, Nonthaburi, Thailand, 11000"}
            </Text>
          </ContactList>
        </List>
      </Right>
    </Container>
  );
};

export default Footer;
