import React from "react";
import styled from "styled-components";
import { mobile } from "../responsive";

const Container = styled.div`
  height: 30px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  //font-weight: bold;
  font-size: 14px;
  background-color: #0c359e;
  padding-left: 50px;
  ${mobile({ fontSize: "10px" })}
`;
const Copyright = () => {
  return (
    <Container>
      <div>Copyright © AGE Consultants Co., Ltd. All Right Reserved </div>
    </Container>
  );
};

export default Copyright;
